import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'sf-font';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3';
import truncateEthAddress from 'truncate-eth-address';
import { Navbar,Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import LOGO from './logo.jpg'
import avatar from './avatar.gif'
import { useRef, useState } from 'react';

const ABI = [
  {
    "inputs":[{"internalType":"string","name":"_name","type":"string"},
    {"internalType":"string","name":"_symbol","type":"string"},
    {"internalType":"string","name":"_initBaseURI","type":"string"},
    {"internalType":"string","name":"_initNotRevealedUri","type":"string"}],
    "stateMutability":"nonpayable","type":"constructor"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"owner","type":"address"},
    {"indexed":true,"internalType":"address","name":"approved","type":"address"},
    {"indexed":true,"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"Approval","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"owner","type":"address"},
    {"indexed":true,"internalType":"address","name":"operator","type":"address"},
    {"indexed":false,"internalType":"bool","name":"approved","type":"bool"}],
    "name":"ApprovalForAll","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"previousOwner","type":"address"},
    {"indexed":true,"internalType":"address","name":"newOwner","type":"address"}],
    "name":"OwnershipTransferred","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"from","type":"address"},
    {"indexed":true,"internalType":"address","name":"to","type":"address"},
    {"indexed":true,"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"Transfer","type":"event"},
    {"inputs":[{"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"approve","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"owner","type":"address"}],
    "name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"baseExtension",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"baseURI",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"cost",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"getApproved",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"owner","type":"address"},
    {"internalType":"address","name":"operator","type":"address"}],
    "name":"isApprovedForAll",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"maxMintAmount",
    "outputs":[{"internalType":"uint256","name":"",
    "type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"maxSupply",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"_mintAmount","type":"uint256"}],
    "name":"mint",
    "outputs":[],"stateMutability":"payable","type":"function"},
    {"inputs":[],"name":"name",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"notRevealedUri",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"owner",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"ownerOf",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"bool","name":"_state","type":"bool"}],
    "name":"pause",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"paused",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"_user","type":"address"}],
    "name":"removeWhitelistUser",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"renounceOwnership",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"reveal","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"revealed",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"safeTransferFrom",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"},
    {"internalType":"bytes","name":"_data","type":"bytes"}],
    "name":"safeTransferFrom","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"operator","type":"address"},
    {"internalType":"bool","name":"approved","type":"bool"}],
    "name":"setApprovalForAll","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_newBaseExtension","type":"string"}],
    "name":"setBaseExtension","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_newBaseURI","type":"string"}],
    "name":"setBaseURI","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"_newCost","type":"uint256"}],
    "name":"setCost","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_notRevealedURI","type":"string"}],
    "name":"setNotRevealedURI","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"_newmaxMintAmount","type":"uint256"}],
    "name":"setmaxMintAmount","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"bytes4",
    "name":"interfaceId","type":"bytes4"}],
    "name":"supportsInterface",
    "outputs":[{"internalType":"bool",
    "name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"symbol",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"index","type":"uint256"}],
    "name":"tokenByIndex",
    "outputs":[{"internalType":"uint256",
    "name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"address",
    "name":"owner","type":"address"},
    {"internalType":"uint256",
    "name":"index","type":"uint256"}],
    "name":"tokenOfOwnerByIndex",
    "outputs":[{"internalType":"uint256",
    "name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"tokenId","type":"uint256"}],
    "name":"tokenURI",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[],
    "name":"totalSupply",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"transferFrom",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"newOwner","type":"address"}],
    "name":"transferOwnership",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"_owner","type":"address"}],
    "name":"walletOfOwner",
    "outputs":[{"internalType":"uint256[]","name":"","type":"uint256[]"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"_user","type":"address"}],
    "name":"whitelistUser","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"","type":"address"}],
    "name":"whitelisted",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"withdraw","outputs":[],
    "stateMutability":"payable",
    "type":"function"}];

const NFTABI = [
  {"inputs":[{"internalType":"string",
  "name":"name","type":"string"},{"internalType":"string",
  "name":"symbol","type":"string"},{"internalType":"string",
  "name":"baseTokenURI","type":"string"}],"stateMutability":"nonpayable","type":"constructor"},
  {"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"owner","type":"address"},
  {"indexed":true,"internalType":"address","name":"approved","type":"address"},{"indexed":true,
  "internalType":"uint256","name":"tokenId","type":"uint256"}],"name":"Approval","type":"event"},
  {"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"owner","type":"address"},
  {"indexed":true,"internalType":"address","name":"operator","type":"address"},
  {"indexed":false,"internalType":"bool","name":"approved","type":"bool"}],
  "name":"ApprovalForAll","type":"event"},{"anonymous":false,
  "inputs":[{"indexed":true,"internalType":"address","name":"previousOwner","type":"address"},
  {"indexed":true,"internalType":"address","name":"newOwner","type":"address"}],
  "name":"OwnershipTransferred","type":"event"},{"anonymous":false,
  "inputs":[{"indexed":true,"internalType":"address","name":"from","type":"address"},
  {"indexed":true,"internalType":"address","name":"to","type":"address"},
  {"indexed":true,"internalType":"uint256","name":"tokenId","type":"uint256"}],
  "name":"Transfer","type":"event"},{"anonymous":false,
  "inputs":[{"indexed":false,"internalType":"address","name":"_from","type":"address"},
  {"indexed":false,"internalType":"uint256","name":"_amount","type":"uint256"}],
  "name":"TransferReceived","type":"event"},{"anonymous":false,
  "inputs":[{"indexed":false,"internalType":"address","name":"_from","type":"address"},
  {"indexed":false,"internalType":"address","name":"_to","type":"address"},
  {"indexed":false,"internalType":"uint256","name":"_amount","type":"uint256"}],
  "name":"TransferSent","type":"event"},{"inputs":[{"internalType":"address",
  "name":"to","type":"address"},{"internalType":"uint256","name":"tokenId","type":"uint256"}],
  "name":"approve","outputs":[],"stateMutability":"nonpayable","type":"function"},
  {"inputs":[{"internalType":"address","name":"owner","type":"address"}],"name":"balanceOf",
  "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view",
  "type":"function"},{"inputs":[],"name":"baseExtension","outputs":[{"internalType":"string",
  "name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],
  "name":"claimRewards","outputs":[],"stateMutability":"nonpayable","type":"function"},
  {"inputs":[],"name":"cost","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
  "stateMutability":"view","type":"function"},{"inputs":[{"internalType":"uint256",
  "name":"tokenId","type":"uint256"}],"name":"getApproved","outputs":[{"internalType":"address",
  "name":"","type":"address"}],"stateMutability":"view","type":"function"},
  {"inputs":[{"internalType":"uint256","name":"tokenId","type":"uint256"}],"name":"getReflectionBalance",
  "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view",
  "type":"function"},{"inputs":[{"internalType":"address","name":"_owner","type":"address"}],
  "name":"getReflectionBalances","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
  "stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"_owner",
  "type":"address"}],"name":"getTokenIds","outputs":[{"internalType":"uint256[]","name":"",
  "type":"uint256[]"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address",
  "name":"owner","type":"address"},{"internalType":"address","name":"operator","type":"address"}],
  "name":"isApprovedForAll","outputs":[{"internalType":"bool","name":"","type":"bool"}],
  "stateMutability":"view","type":"function"},{"inputs":[{"internalType":"uint256","name":"",
  "type":"uint256"}],"name":"lastDividendAt","outputs":[{"internalType":"uint256","name":"",
  "type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"lastSupply",
  "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view",
  "type":"function"},{"inputs":[],"name":"maxMintAmount","outputs":[{"internalType":"uint256",
  "name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],
  "name":"maxSupply","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
  "stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address",
  "name":"_to","type":"address"},{"internalType":"uint256","name":"_mintAmount","type":"uint256"}],
  "name":"mint","outputs":[],"stateMutability":"payable","type":"function"},{"inputs":[],
  "name":"mintReward","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
  "stateMutability":"view","type":"function"},{"inputs":[],"name":"mintSplit",
  "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view",
  "type":"function"},{"inputs":[{"internalType":"uint256","name":"","type":"uint256"}],
  "name":"minters","outputs":[{"internalType":"address","name":"","type":"address"}],
  "stateMutability":"view","type":"function"},{"inputs":[],"name":"name",
  "outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view",
  "type":"function"},{"inputs":[],"name":"owner","outputs":[{"internalType":"address","name":"",
  "type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"uint256",
  "name":"tokenId","type":"uint256"}],"name":"ownerOf","outputs":[{"internalType":"address","name":"",
  "type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"bool",
  "name":"_state","type":"bool"}],"name":"pause","outputs":[],"stateMutability":"nonpayable",
  "type":"function"},{"inputs":[],"name":"paused","outputs":[{"internalType":"bool","name":"",
  "type":"bool"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address",
  "name":"_winner","type":"address"},{"internalType":"uint256","name":"_amount","type":"uint256"}],
  "name":"randomGiveaway","outputs":[],"stateMutability":"nonpayable","type":"function"},
  {"inputs":[],"name":"reflectToOwners","outputs":[],"stateMutability":"payable","type":"function"},
  {"inputs":[],"name":"reflectionBalance","outputs":[{"internalType":"uint256","name":"",
  "type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"uint256",
  "name":"","type":"uint256"}],"name":"remainingIds","outputs":[{"internalType":"uint256","name":"",
  "type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"renounceOwnership",
  "outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address",
  "name":"from","type":"address"},{"internalType":"address","name":"to","type":"address"},
  {"internalType":"uint256","name":"tokenId","type":"uint256"}],"name":"safeTransferFrom",
  "outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address",
  "name":"from","type":"address"},{"internalType":"address","name":"to","type":"address"},
  {"internalType":"uint256","name":"tokenId","type":"uint256"},{"internalType":"bytes",
  "name":"_data","type":"bytes"}],"name":"safeTransferFrom","outputs":[],"stateMutability":"nonpayable",
  "type":"function"},{"inputs":[{"internalType":"address","name":"operator","type":"address"},
  {"internalType":"bool","name":"approved","type":"bool"}],"name":"setApprovalForAll","outputs":[],
  "stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"string",
  "name":"_newBaseExtension","type":"string"}],"name":"setBaseExtension","outputs":[],
  "stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"string",
  "name":"baseURI","type":"string"}],"name":"setBaseURI","outputs":[],"stateMutability":"nonpayable",
  "type":"function"},{"inputs":[{"internalType":"uint256","name":"_newCost","type":"uint256"}],
  "name":"setCost","outputs":[],"stateMutability":"nonpayable","type":"function"},
  {"inputs":[{"internalType":"uint256","name":"_newTokenAmount","type":"uint256"}],
  "name":"setTokenAmount","outputs":[],"stateMutability":"nonpayable","type":"function"},
  {"inputs":[{"internalType":"uint256","name":"_newmaxMintAmount","type":"uint256"}],
  "name":"setmaxMintAmount","outputs":[],"stateMutability":"nonpayable","type":"function"},
  {"inputs":[{"internalType":"bytes4","name":"interfaceId","type":"bytes4"}],"name":"supportsInterface",
  "outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"view","type":"function"},
  {"inputs":[],"name":"symbol","outputs":[{"internalType":"string","name":"","type":"string"}],
  "stateMutability":"view","type":"function"},{"inputs":[],"name":"token",
  "outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view",
  "type":"function"},{"inputs":[],"name":"tokenAmount","outputs":[{"internalType":"uint256",
  "name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],
  "name":"tokenBalance","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
  "stateMutability":"view","type":"function"},{"inputs":[{"internalType":"uint256",
  "name":"index","type":"uint256"}],"name":"tokenByIndex","outputs":[{"internalType":"uint256",
  "name":"","type":"uint256"}],"stateMutability":"view","type":"function"},
  {"inputs":[{"internalType":"address","name":"owner","type":"address"},{"internalType":"uint256",
  "name":"index","type":"uint256"}],"name":"tokenOfOwnerByIndex","outputs":[{"internalType":"uint256",
  "name":"","type":"uint256"}],"stateMutability":"view","type":"function"},
  {"inputs":[{"internalType":"uint256","name":"_id","type":"uint256"}],"name":"tokenURI",
  "outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view",
  "type":"function"},{"inputs":[],"name":"totalDividend","outputs":[{"internalType":"uint256",
  "name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],
  "name":"totalSupply","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
  "stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address",
  "name":"from","type":"address"},{"internalType":"address","name":"to","type":"address"},
  {"internalType":"uint256","name":"tokenId","type":"uint256"}],"name":"transferFrom","outputs":[],
  "stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address",
  "name":"newOwner","type":"address"}],"name":"transferOwnership","outputs":[],
  "stateMutability":"nonpayable","type":"function"},{"stateMutability":"payable","type":"receive"}]

const TOKENABI = [
  {
    inputs: [
      { internalType: "string", name: "_name", type: "string" },
      { internalType: "string", name: "_symbol", type: "string" },
      { internalType: "string", name: "_initBaseURI", type: "string" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [{ internalType: "address[]", name: "_users", type: "address[]" }],
    name: "addUsersToPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseExtension",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cost",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxMintAmount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "uint256", name: "_mintAmount", type: "uint256" },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bool", name: "_state", type: "bool" }],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "presaleCost",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address[]", name: "_users", type: "address[]" }],
    name: "removeUsersFromPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "string", name: "_newBaseExtension", type: "string" },
    ],
    name: "setBaseExtension",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "string", name: "_newBaseURI", type: "string" }],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_newCost", type: "uint256" }],
    name: "setCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_newCost", type: "uint256" }],
    name: "setPresaleCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_newmaxMintAmount", type: "uint256" },
    ],
    name: "setmaxMintAmount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
    name: "tokenByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "index", type: "uint256" },
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_owner", type: "address" }],
    name: "walletOfOwner",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "whitelistedForPresale",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

var web3 = null;
var account = null;
var contract = null;
var nftcontract = null;
var tokencontract = null;

const ADDRESS = "0x0a83cf5aeedfcef788dbae4ca7ef61e78c8f2c85";

const NFTADDRESS = "0x648c9C7e6296B03B247A0cd8fC92C272b8bb49Da";

const TOKENADDRESS = "0x824293562F37Dc988f9816A5aD3fefE139A9B2cc";




const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed1.binance.org'
      },
      chainId: 56
    }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});





async function show() {
  var rawTicketCost = await contract.methods.getLatestPrice().call()
  console.log(rawTicketCost)
  var int = String(rawTicketCost).split(",")[0]
  var cost = Web3.utils.fromWei(int);
  var ticketCost = Number(cost).toFixed(5);
  document.getElementById("ticket-cost").innerHTML = ticketCost;
  var getbalance = await contract.methods.balanceOf(account).call()
  var bal = String(getbalance).split(",")[0];
  var balrwd = Web3.utils.fromWei(bal);
  var totalbal = Number(balrwd).toFixed(2);
  document.getElementById("total-token").textContent = totalbal;
}

  function refreshPage() {
    window.location.reload(false);
  }



function TokenSale () {
  const ticketAmountRef = useRef()
  const [nft, setNft] =  useState([])

  async function connectWallet() {
    var provider = await web3Modal.connect();
    web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();
    account = accounts[0];
    var finalAccount = `${truncateEthAddress(account)}`
    document.getElementById('wallet-address').textContent = finalAccount;
    contract = new web3.eth.Contract(ABI, ADDRESS);
    nftcontract = new web3.eth.Contract(NFTABI, NFTADDRESS);
    tokencontract = new web3.eth.Contract(TOKENABI, TOKENADDRESS)
    var nftDetected = await contract.methods.balanceOf(account).call()
    console.log(nftDetected)
    setNft(nftDetected)

    setTimeout(2000);
  }

  async function buy() {
    var cost = await nftcontract.methods.cost().call();
    var _ticketAmount = Number(ticketAmountRef.current.value)
    var _mintnumber = Number(_ticketAmount * cost)
    nftcontract.methods.mint(account, _ticketAmount).send({ from: account, value: _mintnumber});  }

  console.log(nft.length)

  return(
    <div className='App'>
        <div>
        <div class="topnav">
  <a href="https://tiredfriends.com"><b>HOME</b></a>
  <a href="https://tiredfriends.com/#menu"><b>ROADMAP</b></a>
  <a href="https://dapp.tiredfriends.com"><b>STAKE</b></a>
  <a href="https://rareboard.com/tired-friends"><b>MINT</b></a>
</div>
                    <div>
                </div>
                <div className='col' style={{color:"#5c4673"}}>
            <a href='https://tiredfriends.com' ><img src={LOGO} alt="logo" width='81%' /></a>
            <h1 className='card-title' style={{textDecoration:"underline",color:'orange',textShadow:"1px 1px black"}}>Tired Friends Pre-Sale</h1>

          </div>
        </div>
        
        {nft.length >= 1 ? 
        ( 
      <div className='card mx-auto text-center' id='wrapper' style={{width:"80vw",marginTop:"40px",backgroundColor:"lightgreen"}}>
        
        <div className='card mx-auto text-center' id='item2' style={{backgroundColor: "#0c0b0d",width:"70%",marginTop:"40px",marginBottom:"40px"}}>
        <h4 className='card-title' style={{marginTop:"10px",textDecoration:"none",color:'white'}}>Pre-Sale Price: .05 BNB</h4>
        <a href='#' ><img src={avatar} alt="Preview" id="my-image" /></a>
          <div className='card-title' style={{marginTop:"10px", color:"white",fontWeight:"600"}}><input ref={ticketAmountRef} id="inpt" type="number" name="amount" defaultValue="1" style={{ textAlign: "center", width: "25%",borderRadius:"10px",backgroundColor:"gray" }} /><br/>
                <label for="inpt" style={{ color: "white", fontWeight: "500" }}>
                Mint Amount
                </label></div>
          <Button id='enter-btn' onClick={buy} className='btn btn-information mx-auto text-center' style={{width:'fit-content',marginBottom:"0px"}}>Mint</Button><br/>
          </div>
    </div>) : 
    (<div className='text-center row' style={{}}><div className='col-xxl'><h1 style={{color:"orange", marginTop:"50px",textShadow:"1px 1px black"}}>You must have a<br/>Psychosis Ape in your wallet<br/>to participate in the pre-sale.</h1><br />
              <label type="button" onClick={connectWallet} className="btn btn-secondary mx-auto text-center" for="floatingInput" id="wallet-address" style={{color: "yellow",fontWeight: "700",marginTop: "10px",textShadow: "1px 1px black",}}>Connect Wallet</label><br/><br/>

    </div></div>)
   }
                <div class="fineprint"> The Information Provided On This Website Does Not Constitute Investment Advice, Financial Advice, Trading Advice, Or Any Other Sort Of Advice And You Should Not Treat Any Of The Website’s Content As Such. The Tired Friend Team Does Not Recommend That Any Cryptocurrency or Non-Fungible Tokens Should Be Bought, Sold, Or Held By You. Do Conduct Your Own Due Diligence And Consult Your Financial Advisor Before Making Any Investment Decisions. By Purchasing a Tired Friend, You Agree That You Are Not Purchasing A Security Or Investment And You Agree To Hold The Team Harmless And Not Liable For Any Losses Or Taxes You May Incur. You Also Agree That The Team Is Presenting The Token “As Is” And Is Not Required To Provide Any Support Or Services. You Should Have No Expectation Of Any Form From Tired Friends And Its Team. Although Tired Friends Is A Community Driven Social Experiment And Not A Registered Digital Currency, The Team Strongly Recommends That Citizens In Areas With Government Bans On Crypto Do Not Purchase It Because The Team Cannot Ensure Compliance With Your Territories Regulations. Always Make Sure That You Are In Compliance With Your Local Laws And Regulations Before You Make Any Purchase. Names, Characters, Places, And Incidents Either Are The Product Of The Communities Imagination Or Are Used Fictitiously. Any Resemblance To Actual Persons, Characters, Business Establishments, Events, Or Locales Is Entirely Coincidental.</div>
    </div>
   )
}

export default TokenSale;


