import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useEffect, useState } from 'react'
import font from "./font.ttf";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import axios from 'axios';
import ABI from './ABI.json'
import VAULTABI from './VAULTABI.json';
import truncateEthAddress from 'truncate-eth-address';
import { NFTCONTRACT, STAKINGCONTRACT, moralisapi, nftpng } from './config';
import { Navbar,Nav,NavDropdown,Form,FormControl } from 'react-bootstrap'
import { Link } from 'react-router-dom';

var web3 = null;
var account = null;
var vaultcontract = null;
var contract =  null;

const moralisapikey = "2VBV4vaCLiuGu6Vu7epXKlFItGe3jSPON8WV4CrXKYaNBEazEUrf1xwHxbrIo1oM";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed1.binance.org'
      },
      chainId: 56
    }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});


export default function NFT() {
  const [apicall, getNfts] = useState([])
  const [nftstk, getStk] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')

  useEffect(() => {
  }, [])

  async function callApi() {
    var provider = await web3Modal.connect();
    web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();
    account = accounts[0];
    var finalAccount = `${truncateEthAddress(account)}`
    document.getElementById('wallet-address').textContent = finalAccount;
    contract = new web3.eth.Contract(ABI, NFTCONTRACT);
    vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);

		let config = {'X-API-Key': moralisapikey, 'accept': 'application/json'};
		const nfts = await axios.get((moralisapi + `${account}/nft/0xf262Dc22ca5B2f1F4Ba5FefA6453Ff1c030A99b2?chain=bsc&format=decimal`), {headers: config})
    .then(output => {
        const { result } = output.data
        return result;
      })
    const apicall = await Promise.all(nfts.map(async i => {
      let item = {
        tokenId: i.token_id,
        holder: i.owner_of,
        wallet: account,
      }
      return item
    }))
    const stakednfts = await vaultcontract.methods.tokensOfOwner(account).call()
    .then(id => {
      return id;
    })
    const nftstk = await Promise.all(stakednfts.map(async i => {
      let stkid = {
        tokenId: i,
      }
      return stkid
    }))
      getNfts(apicall)
      getStk(nftstk)
      console.log(apicall);
      setLoadingState('loaded');
 

      var bnbrewards = await contract.methods.getReflectionBalances(account).call();
      var balrwd = Web3.utils.fromWei(bnbrewards);
      var totalbal = Number(balrwd).toFixed(6);
      document.getElementById('bnb-earned').textContent = totalbal;
      

      var getbalance = Number(await contract.methods.balanceOf(account).call());
      document.getElementById('my-staked').textContent = getbalance;
      var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
      const arraynft = Array.from(rawnfts.map(Number));
      const tokenid = arraynft.filter(Number);
      var rwdArray = [];
      tokenid.forEach(async (id) => {
        var rawearn = await vaultcontract.methods.earningInfo(account, [id]).call();
        var array = Array.from(rawearn.map(Number));
        array.forEach(async (item) => {
          var earned = String(item).split(",")[0];
          var earnedrwd = Web3.utils.fromWei(earned);
          var rewardx = Number(earnedrwd).toFixed(2);
          var numrwd = Number(rewardx);
          rwdArray.push(numrwd)
        });
      });
      function delay() {
        return new Promise(resolve => setTimeout(resolve, 300));
      }
      async function delayedLog(item) {
        await delay();
        var sum = item.reduce((a, b) => a + b, 0);
        var formatsum = Number(sum).toFixed(2);
        document.getElementById('earned').textContent = formatsum;
      }
      async function processArray(rwdArray) {
        for (const item of rwdArray) {
          await delayedLog(item);
        }
      }
      return processArray([rwdArray]);
    } 

    async function connectwallet() {
        callApi();
       rewardinfo();
      }


      async function rewardinfo() {


        var bnbrewards = await contract.methods.getReflectionBalances(account).call();
        var balrwd = Web3.utils.fromWei(bnbrewards);
        var totalbal = Number(balrwd).toFixed(6);
        document.getElementById('bnb-earned').textContent = totalbal;

        var getbalance = Number(await contract.methods.balanceOf(account).call());
        document.getElementById('my-staked').textContent = getbalance;
        
        var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
        const arraynft = Array.from(rawnfts.map(Number));
        const tokenid = arraynft.filter(Number);
        var rwdArray = [];
        tokenid.forEach(async (id) => {
          var rawearn = await vaultcontract.methods.earningInfo(account, [id]).call();
          var array = Array.from(rawearn.map(Number));
          array.forEach(async (item) => {
            var earned = String(item).split(",")[0];
            var earnedrwd = Web3.utils.fromWei(earned, 'ether');
            var rewardx = Number(earnedrwd).toFixed(2);
            var numrwd = Number(rewardx);
            rwdArray.push(numrwd)
          });
        });
        function delay() {
          return new Promise(resolve => setTimeout(resolve, 300));
        }
        async function delayedLog(item) {
          await delay();
          var sum = item.reduce((a, b) => a + b, 0);
          var formatsum = Number(sum).toFixed(2);
          document.getElementById('earned').textContent = formatsum;
        }
        async function processArray(rwdArray) {
          for (const item of rwdArray) {
            await delayedLog(item);
          }
        }
        return processArray([rwdArray]);
        
      }
  
    async function enable() {
      contract.methods.setApprovalForAll(STAKINGCONTRACT, true).send({from:account});
    }


    async function claimit() {
      contract.methods.claimRewards().send({from: account})
    }
    async function stakeall() {
      var approval = await contract.methods.isApprovedForAll(account,STAKINGCONTRACT).call();
      console.log(approval)
      if(approval == false) {
        enable();
      }
      else {
        const tokenId = await contract.methods.walletOfOwner(account).call();
            await vaultcontract.methods.stake(tokenId).send({from:account});
           }
          let myInterval = setInterval(callApi(),20000);
           clearInterval(myInterval);
    }

    async function unstakeall() {
      var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
      const arraynft = Array.from(rawnfts.map(Number));
      const tokenid = arraynft.filter(Number);
      await vaultcontract.methods.unstake(tokenid).send({from: account })
      let myInterval = setInterval(callApi(),20000);
      clearInterval(myInterval);
    }

  const refreshNft = ()=>{
    callApi();  
  }

  function refreshPage() {
    window.location.reload(false);
  }

    return(
      <div className='App'>
        <div>
                  <div>
                    </div>
                    <div>
                </div>
          <div className='col' style={{color:"#5c4673"}}><br/>
          <b><Button href='https://metaflip.fun' >Flip</Button>&nbsp;
          <Button href='https://www.rareboard.com/metaflip' >Market</Button>&nbsp;
          <Button href='https://faq.metaflip.fun' >FAQ</Button></b><br/><br/>
          <a href='https://metaflip.fun' ><img src='./logo.png' alt="logo" width='40%' /></a>
          </div>
        </div>
        <div className='card mx-auto text-center' id='wrapper' style={{width:"80vw",marginTop:"0px",backgroundColor:"transparent"}}>
          <h3 className='card-title mt-3'style={{color:"white"}}><b>NFT Reward Center:</b></h3>
          <div className='card mx-auto text-center' id='item' style={{backgroundColor: "transparent",width:"70%",marginTop:"10px"}}>
            <div className='card-title mt-2'style={{color:"white"}}><b>Your Metaflip NFTs owned: <span style={{color:"skyblue"}}  id='my-staked'></span></b></div>
            <div className='card-title mt-1'style={{color:"white"}}><b>Claimable Rewards: <br/><span style={{color:"green"}}  id='bnb-earned'>0</span> BNB</b></div>
            <div className='card-title' style={{color:"white",fontWeight:"400"}}>&nbsp;&nbsp;<span>
            <label type="button" className="btn btn-primary mx-auto text-center" onClick={callApi} for="floatingInput" id="wallet-address" style={{width:'fit-content',marginBottom:"10px"}}>Connect Wallet</label> &nbsp;
            <Button  onClick={claimit}  className='btn btn-primary mx-auto text-center' style={{width:'fit-content',marginBottom:"10px"}}>Claim Rewards</Button></span></div>
          </div><br/>
  
        </div>
        <div className='card mx-auto text-center mb-3' style={{backgroundColor:"#5c4673",width:"80%"}}></div>
        <div className='col'><h1 style={{color:"white",textDecoration:"underline"}} >Your NFTs:</h1></div>
        <Button className='btn btn-secondary'  onClick={refreshNft}>Refresh</Button>
        <div className='nftportal mb-4'>
            <div className="container col-lg-11">
              <div className="row items px-3 pt-3">
                <div className=" ml-3 mr-3 nft-direction" style={{ gridTemplateColumns: "repeat(4, 5fr)", columnGap: "20px" }}>
                  {apicall.map((nft, i) => {
                    var owner = nft.wallet.toLowerCase();
                      if (owner.indexOf(nft.holder) !== -1) {
                    async function stakeit() {
                      vaultcontract.methods.stake([nft.tokenId]).send({ from: account });
                     let myInterval = setInterval(callApi(),20000);
                        clearInterval(myInterval);
                    }
                    return (
                      <div className="card nft-card mt-3 mb-3" key={i} >
                        
                        <div className="card-caption col-12 p-0">
                          <div className="card-body" >
                            <h5 className="mb-0">MetaFlip NFT #{nft.tokenId}</h5>
                          </div>
                          <div className="image-over">
                          <img className="card-img-top" src={nftpng + nft.tokenId + '/720/image.jpg'} alt="" />
                        </div>
                        </div>
                      </div>
                    )}})}
                    {nftstk.map((nft, i) => {
                      async function unstakeit() {
                        vaultcontract.methods.unstake([nft.tokenId]).send({ from: account });
                       let myInterval = setInterval(callApi(),20000);
                        clearInterval(myInterval);
                      }
                      return (
                        <div>
                        </div>
                      )})}
                </div>
              </div>
            </div>
            </div>
            <h6 className='card-title' style={{color:"white"}}>Copyright Ⓒ 2023 MetaFlip</h6>
      </div>
  )
    }
    