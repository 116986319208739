import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'sf-font';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3';
import truncateEthAddress from 'truncate-eth-address';
import { Navbar,Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import LOGO from './art.png'
import { useState } from 'react';

const NFTABI = [
  {
    "inputs":[{"internalType":"string","name":"_name","type":"string"},
    {"internalType":"string","name":"_symbol","type":"string"},
    {"internalType":"string","name":"_initBaseURI","type":"string"},
    {"internalType":"string","name":"_initNotRevealedUri","type":"string"}],
    "stateMutability":"nonpayable","type":"constructor"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"owner","type":"address"},
    {"indexed":true,"internalType":"address","name":"approved","type":"address"},
    {"indexed":true,"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"Approval","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"owner","type":"address"},
    {"indexed":true,"internalType":"address","name":"operator","type":"address"},
    {"indexed":false,"internalType":"bool","name":"approved","type":"bool"}],
    "name":"ApprovalForAll","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"previousOwner","type":"address"},
    {"indexed":true,"internalType":"address","name":"newOwner","type":"address"}],
    "name":"OwnershipTransferred","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"from","type":"address"},
    {"indexed":true,"internalType":"address","name":"to","type":"address"},
    {"indexed":true,"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"Transfer","type":"event"},
    {"inputs":[{"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"approve","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"owner","type":"address"}],
    "name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"baseExtension",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"baseURI",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"cost",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"getApproved",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"owner","type":"address"},
    {"internalType":"address","name":"operator","type":"address"}],
    "name":"isApprovedForAll",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"maxMintAmount",
    "outputs":[{"internalType":"uint256","name":"",
    "type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"maxSupply",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"_mintAmount","type":"uint256"}],
    "name":"mint",
    "outputs":[],"stateMutability":"payable","type":"function"},
    {"inputs":[],"name":"name",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"notRevealedUri",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"owner",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"ownerOf",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"bool","name":"_state","type":"bool"}],
    "name":"pause",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"paused",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"_user","type":"address"}],
    "name":"removeWhitelistUser",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"renounceOwnership",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"reveal","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"revealed",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"safeTransferFrom",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"},
    {"internalType":"bytes","name":"_data","type":"bytes"}],
    "name":"safeTransferFrom","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"operator","type":"address"},
    {"internalType":"bool","name":"approved","type":"bool"}],
    "name":"setApprovalForAll","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_newBaseExtension","type":"string"}],
    "name":"setBaseExtension","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_newBaseURI","type":"string"}],
    "name":"setBaseURI","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"_newCost","type":"uint256"}],
    "name":"setCost","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_notRevealedURI","type":"string"}],
    "name":"setNotRevealedURI","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"_newmaxMintAmount","type":"uint256"}],
    "name":"setmaxMintAmount","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"bytes4",
    "name":"interfaceId","type":"bytes4"}],
    "name":"supportsInterface",
    "outputs":[{"internalType":"bool",
    "name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"symbol",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"index","type":"uint256"}],
    "name":"tokenByIndex",
    "outputs":[{"internalType":"uint256",
    "name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"address",
    "name":"owner","type":"address"},
    {"internalType":"uint256",
    "name":"index","type":"uint256"}],
    "name":"tokenOfOwnerByIndex",
    "outputs":[{"internalType":"uint256",
    "name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"tokenId","type":"uint256"}],
    "name":"tokenURI",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[],
    "name":"totalSupply",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"transferFrom",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"newOwner","type":"address"}],
    "name":"transferOwnership",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"_owner","type":"address"}],
    "name":"tokensOfOwner",
    "outputs":[{"internalType":"uint256[]","name":"","type":"uint256[]"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"_user","type":"address"}],
    "name":"whitelistUser","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"","type":"address"}],
    "name":"whitelisted",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"withdraw","outputs":[],
    "stateMutability":"payable",
    "type":"function"}];


var web3 = null;
var account = null;
var nftcontract = null;

const NFTADDRESS = "0xba332f9bb5338017945081447a56c907d0ddd7cd";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed1.binance.org'
      },
      chainId: 56
    }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});

function TokenSale () {
  const [nft, setNft] =  useState([])

  async function connectWallet() {
    var provider = await web3Modal.connect();
    web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();
    account = accounts[0];
    var finalAccount = `${truncateEthAddress(account)}`
    document.getElementById('wallet-address').textContent = finalAccount;
    nftcontract = new web3.eth.Contract(NFTABI, NFTADDRESS);
    var nftDetected = await nftcontract.methods.tokensOfOwner(account).call()
    console.log(nftDetected)
    setNft(nftDetected)
  }


  console.log(nft.length)

  return(
    <div className='App'>
        <div>
                  <div>
                  <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent:"space-between"}}>
                            <Nav className="mr-auto">
                            <Nav.Link href="https://www.metaraffes.com"><b>Home</b></Nav.Link>
                            <Nav.Link href="https://rareboard.com/metaraffes"><b>Marketplace</b></Nav.Link>
                            <Nav.Link> <Link style={{textDecoration:"none",color:"#C0C0C0"}} to="/#"><b>Staking</b></Link></Nav.Link> 
                           <Nav.Link href="/goth"><b>Great Online Treasure Hunt</b></Nav.Link>
                           <Nav.Link href="https://metaraffes.store"><b>Mint Now</b></Nav.Link>
                            </Nav>
                            <label type="button" onClick={connectWallet} className="btn btn-secondary" for="floatingInput" id="wallet-address" style={{color: "yellow",fontWeight: "700",marginTop: "0px",textShadow: "1px 1px black",}}>Connect Wallet</label>
                        </Navbar.Collapse>
                    </Navbar>
                    </div>
                    <div>
                </div>
                <div className='col' style={{color:"#5c4673"}}>
            <a href='https://metaraffes.com' ><img src={LOGO} alt="logo" width='35%' /></a>
            <h3 className='card-title' style={{textDecoration:"underline",color:'white'}}>Free Holder's Only Adventure Game</h3>

          </div>
        </div>
        
        {nft.length >= 1 ? 
        ( 
      <div className='card mx-auto text-center' id='wrapper' style={{width:"80vw",marginTop:"40px",backgroundColor:"green"}}>
        <div className='card mx-auto text-center' id='item' style={{backgroundColor: "#0c0b0d",width:"70%",marginTop:"40px"}}>
          <h2 className='card-title' style={{textDecoration:"underline"}}> Welcome to the Official GOTH invitation Portal</h2>
          <h4 className='card-title' style={{textDecoration:"underline"}}> -CONNECTED-</h4>
          <div className='text-center row' style={{}}><div className='col-xxl'><h1 style={{color:"lightblue", marginTop:"20px",textShadow:"1px 1px grey"}}>Thank you for verifying.<br/>Click below to start.</h1><br /><a href='https://t.me/+yjupJWLwR8wxZTBh'><Button style={{fontSize:"20px"}}>Join the TeleGram Game Lobby</Button></a>
          <br/>https://t.me/+yjupJWLwR8wxZTBh <button style={{fontSize:"20px"}} onClick={() =>  navigator.clipboard.writeText('https://t.me/+yjupJWLwR8wxZTBh')}>Copy invite link</button>
<br/></div></div><br/>
          </div>
<br/><br/>
    </div>) : 
    (<div className='text-center row' style={{}}><div className='col-xxl'><h1 style={{color:"lightblue", marginTop:"20px",textShadow:"1px 1px grey"}}>You must hold a Florkinomics NFT<br/>in a connected wallet to continue.</h1><br /><a href='https://www.florkinomics.com/'><Button style={{fontSize:"20px",textShadow:"1px 1px black"}}> Minting Website</Button></a>
    <br/><br/>
    <label type="button" onClick={connectWallet} className="btn btn-primary" for="floatingInput" id="wallet-address" style={{color: "white",fontWeight: "450",fontSize:"21px",marginTop: "0px",textShadow: "1px 1px black",}}>Connect Wallet</label>
    </div></div>)
   }
    </div>
   )
}

export default TokenSale;


