import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useEffect, useState } from 'react'
import 'sf-font';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import axios from 'axios';
import ABI from './ABI.json'
import VAULTABI from './VAULTABI.json';
import truncateEthAddress from 'truncate-eth-address';
import { useRef } from 'react';
import { NFTCONTRACT, STAKINGCONTRACT, moralisapi, nftpng } from './confige';
import { Navbar,Nav,NavDropdown,Form,FormControl } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom';

var web3 = null;
var account = null;
var vaultcontract = null;
var contract =  null;


const moralisapikey = "2VBV4vaCLiuGu6Vu7epXKlFItGe3jSPON8WV4CrXKYaNBEazEUrf1xwHxbrIo1oM";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed1.binance.org'
      },
      chainId: 56
    }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});


export default function NFT() {
  const [apicall, getNfts] = useState([])
  const [nftstk, getStk] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')


  useEffect(() => {
  }, [])

  async function callApi() {
    var provider = await web3Modal.connect();
    web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();
    account = accounts[0];
    var finalAccount = `${truncateEthAddress(account)}`
    document.getElementById('wallet-address').textContent = finalAccount;
    contract = new web3.eth.Contract(ABI, NFTCONTRACT);
    vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);
    var approval = await contract.methods.isApprovedForAll(account,STAKINGCONTRACT).call();
    rewardinfo();
    if(approval == false) {
      document.getElementById("approval").style.display = "flex";}
      else{document.getElementById("approval").style.display = "none";}
		let config = {'X-API-Key': moralisapikey, 'accept': 'application/json'};
		const nfts = await axios.get((moralisapi + `${account}/nft/0x648c9c7e6296b03b247a0cd8fc92c272b8bb49da?chain=bsc&format=decimal`), {headers: config})
    .then(output => {
        const { result } = output.data
        return result;
      })
    const apicall = await Promise.all(nfts.map(async i => {
      let item = {
        tokenId: i.token_id,
        holder: i.owner_of,
        wallet: account,
      }
      return item
    }))
    const stakednfts = await vaultcontract.methods.tokensOfOwner(account).call()
    .then(id => {
      return id;
    })
    const nftstk = await Promise.all(stakednfts.map(async i => {
      let stkid = {
        tokenId: i,
      }
      return stkid
    }))
      getNfts(apicall)
      getStk(nftstk)
      console.log(apicall);
      setLoadingState('loaded');

      var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
      const arraynft = Array.from(rawnfts.map(Number));
      const tokenid = arraynft.filter(Number);
      var rwdArray = [];
      tokenid.forEach(async (id) => {
        var rawearn = await vaultcontract.methods.earningInfo(account, [id]).call();
        rawearn.forEach(async (item) => {
          var earned = String(item).split(",")[0];
          var earnedrwd = Web3.utils.fromWei(earned);
          var rewardx = Number(earnedrwd).toFixed(2);
          var numrwd = Number(rewardx);
          rwdArray.push(numrwd)
        });
      });
      function delay() {
        return new Promise(resolve => setTimeout(resolve, 300));
      }
      async function delayedLog(item) {
        await delay();
        var sum = item.reduce((a, b) => a + b, 0);
        var formatsum = Number(sum).toFixed(2);
      }
      async function processArray(rwdArray) {
        for (const item of rwdArray) {
          await delayedLog(item);
        }
      }
      return processArray([rwdArray]);
    } 

    async function connectwallet() {
        callApi();
       rewardinfo();
      }


      async function rewardinfo() {

        var approval = await contract.methods.isApprovedForAll(account,STAKINGCONTRACT).call();
        if(approval == false) {
          document.getElementById("approval").style.display = "flex";}
          else{document.getElementById("approval").style.display = "none";}
        var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
        const arraynft = Array.from(rawnfts.map(Number));
        const tokenid = arraynft.filter(Number);
        var rwdArray = [];
        tokenid.forEach(async (id) => {
          var rawearn = await vaultcontract.methods.earningInfo(account, [id]).call();
          rawearn.forEach(async (item) => {
            var earned = String(item).split(",")[0];
            var earnedrwd = Web3.utils.fromWei(earned, 'ether');
            var rewardx = Number(earnedrwd).toFixed(2);
            var numrwd = Number(rewardx);
            rwdArray.push(numrwd);
          });
        });
        function delay() {
          return new Promise(resolve => setTimeout(resolve, 300));
        }
        async function delayedLog(item) {
          await delay();
          var sum = item.reduce((a, b) => a + b, 0);
          var formatsum = Number(sum).toFixed(2);
        }
        async function processArray(rwdArray) {
          for (const item of rwdArray) {
            await delayedLog(item);
          }
        }
        return processArray([rwdArray]);
        
      }
  
    async function enable() {
      contract.methods.setApprovalForAll(STAKINGCONTRACT, true).send({from:account});
    }


    async function claimit() {
      var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
      const arraynft = Array.from(rawnfts.map(Number));
      const tokenid = arraynft.filter(Number);
            await vaultcontract.methods.claim(tokenid).send({from: account})
    }
    async function stakeall() {
      var approval = await contract.methods.isApprovedForAll(account,STAKINGCONTRACT).call();
      console.log(approval)
      if(approval == false) {
        enable();
      }
      else {
        const tokenId = await contract.methods.getTokenIds(account).call();
            await vaultcontract.methods.stake(tokenId).send({from:account});
           }
          let myInterval = setInterval(callApi(),20000);
           clearInterval(myInterval);
    }

    async function unstakeall(account, tokenIds) {
      var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
      const arraynft = Array.from(rawnfts.map(Number));
      const tokenIdsArray = tokenIds.split(",").map(function(item) {
        return parseInt(item);
      });
      await vaultcontract.methods.unstake(tokenIdsArray).send({from: account });
      let myInterval = setInterval(callApi(),20000);
      clearInterval(myInterval);
    }

  const refreshNft = ()=>{
    callApi();  
  }

  function refreshPage() {
    window.location.reload(false);
  }
  
    return(
      <div className='App'>
        <div>
                  <div>
                  <div class="topnav">
  <a href="https://tiredfriends.com"><b>HOME</b></a>
  <a href="https://tiredfriends.com/#menu"><b>ROADMAP</b></a>
  <a href="https://dapp.tiredfriends.com"><b>STAKE</b></a>
  <a href="https://rareboard.com/tired-friends"><b>MINT</b></a>
</div>
                    </div>
                    <div>
                </div>
          <div className='col' style={{color:"#5c4673"}}>
          <a href='https://tiredfriends.com' ><img src='./logo.jpg' alt="logo" width='81%' /></a>
          </div>
        </div>
        <div className='card mx-auto text-center' id='wrapper' style={{width:"80vw",marginTop:"8px",backgroundColor:"transparent"}}>
          <h2 className='card-title mt-3'style={{color:"darkblue"}}><b>Total Tired Friends Remaining: <span style={{color:"navy"}} id='total-staked'></span></b></h2>
          <div className='card mx-auto text-center' id='item' style={{backgroundColor: "#85C1E9",width:"90%",marginTop:"10px"}}>
            <h4 className='card-title' style={{textDecoration:"underline",marginTop:"6px"}}><b>Please remove your friends and stake in the launch pool.</b></h4>
            <label type="button" className="btn btn-secondary mx-auto text-center" onClick={callApi} for="floatingInput" id="wallet-address" style={{color: "white",fontWeight: "700",width:'fit-content',marginTop:"10px",marginBottom:"10px"}}>Connect Wallet</label>
            <div className='card-title' style={{color:"white",fontWeight:"400"}}>
            <h6 id='approval' className='card mx-auto text-center' style={{width:'fit-content',display:"none",color:"red",marginBottom:"18px",marginTop:"10px",fontSize:"15px",fontWeight:"bolder"}}>&nbsp;&nbsp;&nbsp;First time? Click 'Stake All' once, before you begin, to grant wallet access to the dApp.</h6>
<div>
<input type="text" id="input-value" placeholder="Token IDs" />
<br/><Button  onClick={() => unstakeall(account, document.getElementById("input-value").value)} className='btn btn-danger mx-auto text-center' style={{width:'fit-content',marginTop:"10px",marginBottom:"10px"}}>Unstake All</Button>
<br/>Enter token IDs separated by comma
</div></div>
          </div>  
<br/>
        </div>
        <div className='col'><h1 style={{color:"black",textDecoration:"underline"}} >YOUR NFT'S</h1></div>
        <div className='nftportal mb-4'>
            <div className="container col-lg-11">
              <div className="row items px-3 pt-3">
                <div className=" ml-3 mr-3 nft-direction" style={{ gridTemplateColumns: "repeat(4, 5fr)", columnGap: "10px" }}>
                  {apicall.map((nft, i) => {
                    var owner = nft.wallet.toLowerCase();
                      if (owner.indexOf(nft.holder) !== -1) {
                    async function stakeit() {
                      vaultcontract.methods.stake([nft.tokenId]).send({ from: account });
                     let myInterval = setInterval(callApi(),20000);
                        clearInterval(myInterval);
                    }
                    return (
                      <div className="card nft-card mt-3 mb-3" key={i} >
                        <div className="image-over">
                          <img className="card-img-top" src={nftpng + nft.tokenId + '.png'} alt="" />
                        </div>
                        <div className="card-caption col-12 p-0">
                          <div className="card-body" >
                            <h5 className="mb-0">Tired Friend #{nft.tokenId}</h5>
                            <h5 className="mb-0 mt-2">STATUS:<p style={{ color: "#39FF14", fontWeight: "bold", textShadow: "1px 1px 2px #000000" }}>Awake</p></h5>
                            <div className="card-bottom d-flex justify-content-between">
                              <input key={i} type="hidden" id='stakeid' value={nft.tokenId} />
                              <Button className='btn btn-info mx-auto text-center' onClick={stakeit}>Put to Bed</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}})}
                    {nftstk.map((nft, i) => {
                      async function unstakeit() {
                        vaultcontract.methods.unstake([nft.tokenId]).send({ from: account });
                       let myInterval = setInterval(callApi(),20000);
                        clearInterval(myInterval);
                      }
                      return (
                        <div>
                        
                        <div className="card stakedcard mt-3 mb-3" key={i} >
                          <div className="image-over">
                            <img className="card-img-top" src={nftpng + nft.tokenId + '.png'} alt="image" />
                          </div>
                          <div className="card-caption col-12 p-0">
                            <div className="card-body">
                              <h5 className="mb-0">Tired Friend #{nft.tokenId}</h5>
                              <h5 className="mb-0 mt-2">STATUS:<p style={{ color: "#15F4EE", fontWeight: "bold", textShadow: "1px 1px 2px #000000" }}>Currently Asleep</p></h5>
                              <div className="card-bottom d-flex justify-content-between">
                                <input key={i} type="hidden" id='stakeid' value={nft.tokenId} />
                                <Button className='btn btn-dark mx-auto text-center' onClick={unstakeit}>Wake Up</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      )})}
                </div>
              </div>
            </div>
            </div>
            <div class="fineprint"> The Information Provided On This Website Does Not Constitute Investment Advice, Financial Advice, Trading Advice, Or Any Other Sort Of Advice And You Should Not Treat Any Of The Website’s Content As Such. The Tired Friend Team Does Not Recommend That Any Cryptocurrency or Non-Fungible Tokens Should Be Bought, Sold, Or Held By You. Do Conduct Your Own Due Diligence And Consult Your Financial Advisor Before Making Any Investment Decisions. By Purchasing a Tired Friend, You Agree That You Are Not Purchasing A Security Or Investment And You Agree To Hold The Team Harmless And Not Liable For Any Losses Or Taxes You May Incur. You Also Agree That The Team Is Presenting The Token “As Is” And Is Not Required To Provide Any Support Or Services. You Should Have No Expectation Of Any Form From Tired Friends And Its Team. Although Tired Friends Is A Community Driven Social Experiment And Not A Registered Digital Currency, The Team Strongly Recommends That Citizens In Areas With Government Bans On Crypto Do Not Purchase It Because The Team Cannot Ensure Compliance With Your Territories Regulations. Always Make Sure That You Are In Compliance With Your Local Laws And Regulations Before You Make Any Purchase. Names, Characters, Places, And Incidents Either Are The Product Of The Communities Imagination Or Are Used Fictitiously. Any Resemblance To Actual Persons, Characters, Business Establishments, Events, Or Locales Is Entirely Coincidental.</div>

      </div>
  )
    }
    