export const NFTCONTRACT = "0x799DCdC7D25C78CCd8A52df217EB1E7589A55554"
export const STAKINGCONTRACT = "0x10D2325cf0bA811be3DAa6318E65813294919FB5"
export const TOKENCONTRACT = "0x4f890381abe9917a1cfed7951b7eb79900f4830d"
export const bscscanapi = "https://api.bscscan.com/"
export const moralisapi = "https://deep-index.moralis.io/api/v2/";
export const nftpng = "https://ipfs.io/ipfs/QmNemozLN3VZzUbH5J83anVLECZh4bvAyS38wpPxJpXeqx/";


/*
    const unstakednfts = await contract.methods.walletOfOwner(account).call()
      .then(id => {
          return id;
          console.log(id);
        })
        const nftunstk = await Promise.all(unstakednfts.map(async i => {
          let unstkid = {
            tokenId: i,
          }
          return unstkid
        }))
        getNfts(apicall)
        getunStk(nftunstk)
        getStk(nftstk)
        console.log(apicall);
        setLoadingState('loaded')
      }


      const NFTCONTRACT = "0x06A546A6FeE87aBc45f32dFb630b9A4c69eA243a";
const STAKINGCONTRACT = "0x32b598a60EBe5f3b06d4b9D6571D2a8d9fe0d7E8";
const polygonscanapikey = "DBQX5JUSAVUZRK8CC4IN2UZF9N2HA63P4U";
const polygonscanapi = "https://api-testnet.polygonscan.com/api"
const moralisapi = "https://deep-index.moralis.io/api/v2/";
const moralisapikey = "pC41kxQ1eakXfyd0ME3DEgmGGkTxQnF0iLLygWMeeDni2mLrGoKmNw5hxUKWIWHC";
const nftpng = "https://ipfs.io/ipfs/QmYh538cj3pfW1uPeKB86ihMNuaAitoZv14T2ZFjxoPjBj/";

*/

/* 
    const unstakednfts = await contract.methods.walletOfOwner(account).call()
    .then(id => {
      return id;
    })
    const nftunstk = await Promise.all(unstakednfts.map(async i => {
      let stkid = {
        tokenId: 1,
      }
      return stkid;
    }))
*/