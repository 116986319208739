import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'sf-font';
import './App.css';
import ReactDom from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import Lottery from './Lottery'
import TokenSale from './TokenSale';
import Lottery2 from './Lottery2';
import Padmon from './padmon';
import GOTH from './goth';
import Drunkskunkgoth from './drunkskunkgoth';
import Reflectdogegoth from './reflectdogegoth';
import Badseedsgoth from './badseedsgoth';
import Florkgoth from './florkgoth';
import StakingBeta from './stakingbeta';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


ReactDom.render(
  <BrowserRouter>
  <Switch>
    <Route path='/' exact>
    <App />
    </Route>
    <Route path='/lottery'>
    <Lottery />
    </Route>
    <Route path='/TokenSale'>
    <TokenSale />
    </Route>
    <Route path='/goth'>
    <GOTH />
    </Route>
    <Route path='/lottery2'>
    <Lottery2 />
    </Route>
    <Route path='/padmon'>
    <Padmon />
    </Route>
    <Route path='/drunkskunkgoth'>
    <Drunkskunkgoth />
    </Route>
    <Route path='/reflectdogegoth'>
    <Reflectdogegoth />
    </Route>
    <Route path='/badseedsgoth'>
    <Badseedsgoth />
    </Route>
    <Route path='/florkgoth'>
    <Florkgoth />
    </Route>
    <Route path='/stakingbeta'>
    <StakingBeta />
    </Route>
  </Switch>
  </BrowserRouter>
, document.getElementById('root'))


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
